'use strict';

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

let Password = {};

$('body').on('click', '.password-plain-text', function() {
    let $this = $(this);
    let $input = $this.parents('.input-group').find('input');
    let showPlainText = ($input.attr('type') === 'password');
    $this.toggleClass('is-plain-text');
    if (showPlainText) {
        $input.attr('type', 'text').on('cut copy paste', function(e) {
            e.preventDefault();
        });
    } else {
        $input.attr('type', 'password');
    }
});

$('body').on('focusin', 'input', function() {
    Password.togglePasswordIcon($(this), true);
}).on('focusout', 'input', function() {
    Password.togglePasswordIcon($(this), false);
});

Password.togglePasswordIcon = function($passwordInput, isSelected) {
    let $passwordPlainText = $passwordInput.parents('.input-group').find('.password-plain-text');
    if ($passwordPlainText.length > 0) {
        $passwordPlainText.toggleClass('selected', isSelected);
    }
};